<template>
	<div class="mhome">
		<div class="mhome-wrap"  @scroll="handleScroll">
			<div class="mstore-head" :class="{ mfixed: scrollFlag }">
				<div class="head-main" v-show="!scrollFlag">
					<router-link class="user-info" :to="{name:'mStoreImpression',params:{id:$route.params.id}}">
						<div class="name">{{storeInfo.store_name}}</div>
						<div class="text">{{$t('mobile.tags.fans')+':'}}{{storeInfo.count_fans}}</div>
					</router-link>
					<div class="bar">
						<el-button type="info" size="mini" @click="handleUnFollow" v-if="storeInfo.is_follow==1">{{$t('mobile.actions.unfollow')}}</el-button>
						<el-button type="danger" size="mini" @click="handleFollow" v-else>{{'+'+$t('mobile.actions.follow')}}</el-button>
					</div>
				</div>
				<div class="head-nav">
					<ul>
						<li><router-link :to="{name: 'mStoreInfo',params:{id:$route.params.id}}" class="text">{{$t('mobile.tags.home')}}</router-link></li>
						<li class="active"><router-link :to="{name: 'mStoreGoods',params:{id:$route.params.id}}" class="text">{{$t('mobile.tags.baby')}}</router-link></li>
					</ul>
				</div>
				<div class="head-screen">
					<div class="item" :class="{ active: sortKey === 'all' }" @click="handleGoodsChange('all')"><span>{{$t('mobile.shop.all')}}</span></div>
					<div class="item" :class="{ active: sortKey === 'sales' }" @click="handleGoodsChange('sales')"><span>{{$t('mobile.shop.sales')}}</span></div>
					<div class="item" :class="{ active: sortKey === 'news' }" @click="handleGoodsChange('news')"><span>{{$t('mobile.shop.news')}}</span></div>
					<div class="item" @click="handleGoodsChange('price')">
						<span>{{$t('mobile.shop.price')}}</span>
						<span class="caret-wrapper">
							<i class="el-icon-caret-top" :class="sortKey==='price'&&sortType==='asc'?'active':''"></i>
							<i class="el-icon-caret-bottom" :class="sortKey==='price'&&sortType==='desc'?'active':''"></i>
						</span>
					</div>
					<div class="item"><span class="el-icon-menu"></span></div>
				</div>
			</div>
			<div v-show="scrollFlag" style="height: 3.104rem;"></div>
			<div class="mstore-main">
				<div class="mstore-goods">
					<router-link v-for="(item,index) in goodsList" :key="index"
						:to="{ name: 'mGoodsInfo', params: { id: item.goods_id }}" class="item">
						<div class="pic"><img :src="item.default_image" @error="imageLoadErrorGoods"></div>
						<div class="item-main">
							<div class="name">{{item.goods_name}}</div>
							<div class="text">
								<div class="price">￥<span>{{item.price}}</span></div>
								<div class="number">{{item.sales?item.sales:0}}{{$t('mobile.tags.sales')}}</div>
							</div>
						</div>
					</router-link>
				</div>
				<div v-if="!pageFlag" class="mstore-foot">—— {{$t('mobile.tags.listEnd')}} ——</div>
			</div>
		</div>
	</div>
</template>
<script>
	import { fetchShopInfo, getShopGoods, onCollect, onUncollect, browseStore } from '@/api/mobile/store';
	import { getToken } from '@/utils/auth';
	export default {
		data() {
			return {
				scrollFlag: false,
				storeInfo: {},
				goodsList: [],
				pageTotal: 0,
				pageCurr: 1,
				pageSize: 10,
				pageFlag: true,
				sortKey: 'all',
				sortType: 'desc',
			}
		},
		mounted() {
			this.getStoreInfo();
		},
		methods: {
			imageLoadErrorGoods(e) {
				e.target.src = this.$imageErrorGoods;
			},
			async getStoreInfo() {
				try {
					const params = {
						store_id: this.$route.params.id
					}
					const info = await fetchShopInfo(params)
					this.storeInfo = info.data

					await browseStore({...params, from: 4 })
					this.getGoodsList()
				} catch (error) {
					this.$router.go(-1)
				}
			},
			getGoodsList() {
				let loading = this.$loading();
				let param = {
					sort_key: this.sortKey,
					sort_type: this.sortType,
					store_id: this.$route.params.id,
					page_index: (this.pageCurr - 1) * this.pageSize,
					page_size: this.pageSize
				}
				getShopGoods(param).then(response => {
					loading.close();
					if (response.data.list.length > 0) {
						this.goodsList = this.goodsList.concat(response.data.list);
					}
					if(response.data.list.length<this.pageSize) {
						this.pageFlag = false;
					}
					this.pageTotal = parseInt(response.data.count);
				}).catch(() => {
					loading.close();
				})
			},
			handleScroll($event) {
				var scrollTop = $event.target.scrollTop;
				var _height = document.querySelector(".mstore-head").offsetHeight;
				if (scrollTop > _height) {
					this.scrollFlag = true;
				} else {
					this.scrollFlag = false;
				}
				var offsetHeight = $event.target.offsetHeight;
				var scrollHeight = $event.target.scrollHeight;
				if(offsetHeight+scrollTop>=scrollHeight && this.pageFlag){
					this.pageCurr++;
					this.getGoodsList();
				}
			},
			handleFollow(){
				const hasToken = getToken()
				if(!hasToken){
					this.$message({
						message: this.$t('mobile.errorMsg.login'),
						customClass: 'wap-message',
						type: 'warning',
						duration: 1500,
						onClose:()=>{
							window.location.href = this.$loginBasePath+'/m/?jump_url='+window.location.href;
						}
					});
					return false;
				}
				let param = {
					type: 'store',
					followId: this.storeInfo.store_id,
					uuid: this.$store.getters.uuid,
					app_key: this.$store.getters.appKey
				}
				onCollect(param).then(() => {
					this.$message({
						message: this.$t('mobile.successMsg.follow'),
						customClass: 'wap-message',
						type: 'success'
					});
					this.getStoreInfo();
				})
			},
			handleUnFollow(){
				const hasToken = getToken()
				if(!hasToken){
					this.$message({
						message: this.$t('mobile.errorMsg.login'),
						customClass: 'wap-message',
						type: 'warning',
						duration: 1500,
						onClose:()=>{
							window.location.href = this.$loginBasePath+'/m/?jump_url='+window.location.href;
						}
					});
					return false;
				}
				let param = {
					type: 'store',
					followId: this.storeInfo.store_id,
					uuid: this.$store.getters.uuid,
					app_key: this.$store.getters.appKey
				}
				onUncollect(param).then(() => {
					this.$message({
						message: this.$t('mobile.cancelMsg.follow'),
						customClass: 'wap-message',
						type: 'warning'
					});
					this.getStoreInfo();
				})
			},
			handleGoodsChange(type){
				this.goodsList = [];
				this.pageCurr = 1;
				this.sortKey = type;
				if(type=='price' && this.sortType == 'asc'){
					this.sortType = 'desc';
				}else{
					this.sortType = 'asc';
				}
				this.pageFlag = true;
				this.getGoodsList();
			}
		}
	}
</script>
<style lang="scss" scoped>
	.mstore-head {
		background-color: #f4f4f4;

		.head-main {
			display: flex;
			justify-content: space-between;
			padding: 0.32rem 0.32rem 0;

			.user-info {
				.name {
					color: #333333;
					font-size: 0.544rem;
					height: 0.928rem;
					line-height: 0.928rem;
				}

				.text {
					color: #999999;
					font-size: 0.416rem;
					height: 0.512rem;
					line-height: 0.512rem;
				}
			}

			.bar {
				::v-deep .el-button {
					width: 1.984rem;
					height: 0.928rem;
					padding: 0.256rem 0.288rem;
					border-radius: 0.48rem;
					font-size: 0.352rem;
					background-color: transparent;
				}

				::v-deep .el-button--danger {
					color: #ff5000;
					border-color: #ff5000;
					background-color: transparent;
				}
				::v-deep .el-button--danger:focus,
				.el-button--danger:hover {
					color: #ff5000;
					border-color: #ff5000;
					background-color: transparent;
				}
				::v-deep .el-button--info {
					color: #606266;
					border-color: #DCDFE6;
					background-color: transparent;
				}
				::v-deep .el-button--info:focus,
				.el-button--info:hover {
					color: #606266;
					border-color: #DCDFE6;
					background-color: transparent;
				}
			}
		}

		.head-nav {
			display: flex;
			height: 1.664rem;
			align-items: center;
			padding: 0 0.32rem;

			li {
				display: inline-block;
				margin-right: 0.768rem;

				.text {
					color: #333333;
					display: block;
					height: 0.704rem;
					font-size: 0.48rem;
					line-height: 0.704rem;
					font-weight: 500;
					border-bottom: 0.064rem solid transparent;
				}
			}

			li.active {
				.text {
					color: #ff5000;
					border-bottom-color: #ff5000;
				}
			}
		}
		.head-screen {
			display: flex;
			justify-content: space-around;
			.item {
				height: 1.44rem;
				line-height: 1.44rem;
				font-size: 0.448rem;
				color: #737373;
			}
			.caret-wrapper {
				display: inline-flex;
				flex-direction: column;
				align-items: center;
				vertical-align: middle;
				i {
					height: 0.256rem;
					line-height: 0.256rem;
				}
			}
			.el-icon-menu {
				font-size: 0.512rem;
			}
			.active {
				color: #ff5000;
			}
		}
	}
	.mstore-main {
		padding: 0 0.32rem;
		.mstore-goods {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		
			.item {
				color: #333333;
				width: 5.52rem;
				overflow: hidden;
				background-color: #FFFFFF;
				border-radius: 0.32rem;
				margin-bottom: 0.32rem;
		
				.pic {
					width: 100%;
					height: 5.52rem;
		
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
		
				.item-main {
					padding: 0.32rem;
		
					.name {
						font-size: 0.448rem;
						height: 1.088rem;
						line-height: 0.544rem;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						white-space: normal;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						margin-bottom: 0.16rem;
					}
		
					.text {
						display: flex;
						line-height: 0.64rem;
						align-items: center;
					}
		
					.price {
						color: #ff5000;
						font-size: 0.384rem;
		
						span {
							font-size: 0.576rem;
						}
					}
		
					.number {
						color: #999999;
						font-size: 0.384rem;
						margin-left: 0.096rem;
					}
				}
			}
		}
		.mstore-foot {
			text-align: center;
			font-size: 0.384rem;
			color: #999999;
			margin: 0.16rem 0 0.48rem;
		}
	}
	.mfixed {
		top: 0;
		left: 0;
		width: 100%;
		z-index: 99;
		box-sizing: border-box;
		position: absolute;
	}
</style>